import React, { FC, ReactNode, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Alert from '../../../components/bootstrap/Alert';
import Dropdown, {
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import { Offcanvas } from 'react-bootstrap';
import { HeaderRight } from './Header';
import { FaRegHospital } from 'react-icons/fa';
import AuthContext from '../../../context/authContext';
import useLogout from '../../../hooks/useLogout';
import Tooltips from '../../Tooltips';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	
	const styledBtn: IButtonProps = {
		color: 'light',
		hoverShadow: 'default',
		isLight: true,
		size: 'lg',
	};

	const logout = useLogout();
	const { userData, unidade, handleOpenSupport } = useContext(AuthContext);
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { t, i18n } = useTranslation(['mensagem', 'menu']);

	const handleLogout = () => {
		logout();
	};

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren && beforeChildren}
				

				{/*	Notifications */}
				{ false && (
					<div className='col-auto'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon='Notifications'
							color='primary'
							isLight={false}
							onClick={() => setOffcanvasStatus(true)}
							aria-label='Notifications'
						/>
					</div>
				)}
				

				<div className='col-auto'>
					<Tooltips
						placement='bottom'
						title={'Suporte'}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								id='btn-suporte-header'
								icon='ContactSupport'
								color='primary'
								isLight={false}
								onClick={handleOpenSupport}
								aria-label='Suporte'
								className='support'
							/>
					</Tooltips>
				</div>

				{/* Quick Panel */}
				{ (userData && unidade) && (
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle isStatic>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon='Person'
									color='primary'
									isLight={false}
									aria-label='Change language'
									data-tour='lang-selector'>
									<span className='fs-6'>{userData.login}</span>
								</Button>
							</DropdownToggle>
							<DropdownMenu isCloseAfterLeave={false} size='xl' className='py-0 overflow-hidden'>
								<div className='row g-0'>
									<div className='col-12 pt-3 d-flex justify-content-center fw-bold fs-5 text-info bg-l25-info'>
										Olá {userData.nome}
									</div>
									<div className='col-12 pb-3 d-flex justify-content-center bg-l25-info border-bottom border-info'>
										{userData.email}
									</div>
									{ unidade && (
										<div
											className={classNames(
												'col-12 p-4 transition-base',
												'border-bottom',
											)}>
											<div className='d-flex align-items-center text-muted'>
												<FaRegHospital className='fs-4 me-2' />
												<span>{unidade.nome}</span>
											</div>
										</div>
									)}
									<div
										className={classNames(
											'col-12 p-4 transition-base cursor-pointer bg-light-hover',
											'border-bottom',
											'hover-shadow-default',
										)}
										onClick={() => window.open('https://www.kpih.com.br/acesso/paginaInicial','_blank')}>
										<div className='d-flex align-items-center'>
											<div className='flex-grow-1'>
												<Icon icon='Public' size='2x' color='info' className='me-2' />
												<span>KPIH</span>
											</div>
											<small className='text-muted float-end'>Acessar {' '}<Icon icon='OpenInNew' /></small>
										</div>
									</div>

									{ false && (
										<div
											className={classNames(
												'col-12 p-4 transition-base cursor-pointer bg-light-hover',
												'border-bottom',
												'hover-shadow-default',
											)}
											onClick={() => alert('go to https://www.kpih.com.br/acesso/paginaInicial')}>
											<div className='d-flex align-items-center'>
												<div className='flex-grow-1'>
													<Icon icon='ManageAccounts' size='2x' color='dark' className='me-2' />
													{t('menu:minhaConta')}
												</div>
											</div>
										</div>
									)}
									<div
										className={classNames(
											'col-12 p-4 transition-base cursor-pointer bg-light-hover',
											'border-bottom',
											'hover-shadow-default',
										)}
										onClick={() => handleLogout()}>
										<div className='d-flex align-items-center'>
											<div className='flex-grow-1'>
												<Icon icon='Logout' size='2x' color='dark' className='me-2' />
												<span>{t('menu:logout') as ReactNode}</span>
											</div>
										</div>
									</div>
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>
				)}
				{afterChildren}
			</div>

			
			<Offcanvas
				id='notificationCanvas'
				placement='end'
				show={offcanvasStatus}
				onHide={() => setOffcanvasStatus(false)}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title id='offcanvasExampleLabel'>Área de Notificações</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<Alert variant='info' className='flex-nowrap' isDismissible>
						4 novas contas mapeadas.
					</Alert>
					<Alert variant='warning' className='flex-nowrap' isDismissible>
						Novos exames sem produto vinculado.
					</Alert>
					<Alert variant='danger' className='flex-nowrap' isDismissible>
						15 novos atendimentos não identificados.
					</Alert>
				</Offcanvas.Body>
			</Offcanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
