import React, { lazy, useMemo } from 'react';
import Login from '../pages/auth/Login';
import LockScreen from '../pages/lockScreen/LockScreen';
import ReportDownload from '../pages/atendimentos/ReportDownload';

const CP = {
	HOME: lazy(() => import('../pages/home/HomePage')),
	ATENDIMENTOS: lazy(() => import('../pages/atendimentos/AtendimentosPage')),
	CUSTO_PROCEDIMENTO: lazy(() => import('../pages/custoProcedimento/CustoProcedimentoPage')),
	MAPEAR_CENTRO_CUSTO_INTEGRACAO: lazy(() => import('../pages/mapeamentos/centroDeCusto/MapeamentoCentroDeCustoPage')),
	MAPEAR_EXAMES_INTEGRACAO: lazy(() => import('../pages/mapeamentos/exames/MapeamentoExamesPage')),
	MAPEAR_PROCEDIMENTOS_INTEGRACAO: lazy(() => import('../pages/mapeamentos/procedimentos/MapeamentoProcedimentosPage')),
	MAPEAR_TIPO_ATENDIMENTO: lazy(() => import('../pages/mapeamentos/tipoAtendimento/MapeamentoTipoAtendimentoPage')),
	MAPEAR_TIPO_CONVENIO: lazy(() => import('../pages/mapeamentos/tipoConvenio/MapeamentoTipoConvenioPage')),
	MAPEAR_GRUPO_RECEITA: lazy(() => import('../pages/mapeamentos/grupoDeReceita/MapeamentoGrupoDeReceita')),
	COMPOSICAO_CUSTOS: lazy(() => import('../pages/configuracoesCalculo/composicaoCustos/ComposicaoCustosPage')),
	STATUS: lazy(() => import('../pages/monitoramento/status/StatusPage')),
	CONFIGURACOES: lazy(() => import('../pages/configuracoes/ConfiguracoesPage')),
	CONVERSOR: lazy(() => import('../pages/configuracoesCalculo/conversorMatMed/ConversorMatMedPage')),
	GLOSA: lazy(() => import('../pages/configuracoesCalculo/glosa/GlosaPage')),
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	CONFERENCIA: lazy(() => import('../pages/monitoramento/conferencia/ConferenciaPage')),
	METABASE: lazy(() => import('../pages/monitoramento/metabase/MetabasePage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
};

export interface RoutesProps {
	id: string;
	text: string;
	icon?: string;
	subMenu?: RoutesProps[];
	path?: string;
	element?: JSX.Element;
	roles?: string[];
	onlyRoute?: boolean;
	onlyEnv?: string[];
}
export const enum roles {
	ADMINISTRADOR = 'ADMINISTRADOR',
	USUARIO_UNIDADE = 'USUARIO_UNIDADE',
	ADMINISTRADOR_PLANISA = 'ADMINISTRADOR_PLANISA',
	CONSULTOR_PLANISA = 'CONSULTOR_PLANISA',

	ROLE_ATENDIMENTOS = 'ROLE_ATENDIMENTOS',
	ROLE_PRECIFICACAO = 'ROLE_PRECIFICACAO',
	ROLE_ACOMPANHAMENTO_PROTOCOLO = 'ROLE_ACOMPANHAMENTO_PROTOCOLO',
	ROLE_MAPEAMENTO_CENTRO_CUSTO = 'ROLE_MAPEAMENTO_CENTRO_CUSTO',
	ROLE_MAPEAMENTO_EXAMES = 'ROLE_MAPEAMENTO_EXAMES',
	ROLE_MAPEAMENTO_PROCEDIMENTOS = 'ROLE_MAPEAMENTO_PROCEDIMENTOS',
	ROLE_MAPEAMENTO_TIPOS_ATENDIMENTO = 'ROLE_MAPEAMENTO_TIPOS_ATENDIMENTO',
	ROLE_MAPEAMENTO_TIPOS_FONTE_PAGADORA = 'ROLE_MAPEAMENTO_TIPOS_FONTE_PAGADORA',
	ROLE_MAPEAMENTO_GRUPOS_RECEITA = 'ROLE_MAPEAMENTO_GRUPOS_RECEITA',
	ROLE_COMPOSICAO_CUSTOS = 'ROLE_COMPOSICAO_CUSTOS',
	ROLE_CONVERSOR_MATMED = 'ROLE_CONVERSOR_MATMED',
	ROLE_DEDUCOES_RECEITA = 'ROLE_DEDUCOES_RECEITA',
	ROLE_STATUS = 'ROLE_STATUS',
	ROLE_CONFERENCIA_RECEITA = 'ROLE_CONFERENCIA_RECEITA',
	ROLE_ROTEIRO_ANALISE = 'ROLE_ROTEIRO_ANALISE',
	ROLE_INTEGRACAO = 'ROLE_INTEGRACAO',
	ROLE_INTEGRACAO_LEITURA = 'ROLE_INTEGRACAO_LEITURA',
	ROLE_PERFIS_ACESSO = 'ROLE_PERFIS_ACESSO',
	ROLE_DASHBOARDS = 'ROLE_DASHBOARDS',
}
const contentRoutes:RoutesProps[] = [
	/**
	 * Landing
	 */
	{
		id: 'home',
		text: 'Início',
		path: '/',
		icon: 'Home',
		roles: [],
		element: <CP.HOME />,
	},
	{
		id: 'atendimentos',
		text: 'Atendimentos',
		path: '/atendimentos',
		icon: 'PersonSearch',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_ATENDIMENTOS],
		element: <CP.ATENDIMENTOS />,
	},
	{
		id: 'custo-procedimento',
		text: 'Custo de Procedimentos',
		path: '/custo-procedimento',
		icon: 'Dashboard',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_PRECIFICACAO],
		element: <CP.CUSTO_PROCEDIMENTO />,
	},
	{
		id: 'mapeamentos',
	 	text: 'Mapeamentos',
	 	path: '/mapeamentos',
	 	icon: 'AccountTree',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_CENTRO_CUSTO, roles.ROLE_MAPEAMENTO_EXAMES, roles.ROLE_MAPEAMENTO_GRUPOS_RECEITA, roles.ROLE_MAPEAMENTO_PROCEDIMENTOS, roles.ROLE_MAPEAMENTO_TIPOS_ATENDIMENTO, roles.ROLE_MAPEAMENTO_TIPOS_FONTE_PAGADORA],
	 	subMenu: [
			{
				id: 'centroDeCusto',
				text: 'Centros de Custos',
				path: '/mapear-centro-custo',
				icon: 'MonetizationOn',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_CENTRO_CUSTO],
				element: <CP.MAPEAR_CENTRO_CUSTO_INTEGRACAO />,
			},
			{
				id: 'exames',
				text: 'Exames',
				path: '/mapear-exames',
				icon: 'Biotech',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_EXAMES],
				element: <CP.MAPEAR_EXAMES_INTEGRACAO />,
			},
			{
				id: 'procedimentos',
				text: 'Procedimentos',
				path: '/mapear-procedimentos',
				icon: 'AirlineSeatFlat',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_PROCEDIMENTOS],
				element: <CP.MAPEAR_PROCEDIMENTOS_INTEGRACAO />,
			},
			{
				id: 'tiposDeAtendimento',
				text: 'Tipos de Atendimento',
				path: '/mapear-tipo-atendimento',
				icon: 'Attribution',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_TIPOS_ATENDIMENTO],
				element: <CP.MAPEAR_TIPO_ATENDIMENTO />,
			},
			{
				id: 'tiposDeConvenio',
				text: 'Tipos de Fonte Pagadora',
				path: '/mapear-tipo-fonte-pagadora',
				icon: 'AccountBox',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_TIPOS_FONTE_PAGADORA],
				element: <CP.MAPEAR_TIPO_CONVENIO />,
			},
			{
				id: 'gruposDeReceita',
				text: 'Grupos de Receita',
				path: '/mapear-grupos-receita',
				icon: 'Task',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_MAPEAMENTO_GRUPOS_RECEITA],
				element: <CP.MAPEAR_GRUPO_RECEITA />,
			},
		]
	},
	{
		id: 'composicao',
		text: 'Configurações de Cálculo',
		path: '/composicao-custos',
		icon: 'SettingsInputComposite',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_COMPOSICAO_CUSTOS, roles.ROLE_CONVERSOR_MATMED, roles.ROLE_DEDUCOES_RECEITA],
		subMenu: [
			{
				id: 'composicao',
				text: 'Composição de Custos',
				path: '/composicao-custos',
				icon: 'AttachMoney',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_COMPOSICAO_CUSTOS],
				element: <CP.COMPOSICAO_CUSTOS />,
			},
			{
				id: 'conversor',
				text: 'Conversor de Mat/Med',
				path: '/conversor-matmed',
				icon: 'Medication',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_CONVERSOR_MATMED],
				element: <CP.CONVERSOR />,
			},
			{
				id: 'glosa',
				text: 'Deduções de Receita',
				path: '/deducao-de-receita',
				icon: 'MoneyOff',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_DEDUCOES_RECEITA],
				element: <CP.GLOSA />,
			},
		]
	},
	{
		id: 'monitoramento',
		text: 'Monitoramento',
		path: '/monitoramento',
		icon: 'Monitor',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_STATUS, roles.ROLE_CONFERENCIA_RECEITA, roles.ROLE_ROTEIRO_ANALISE],
		subMenu: [
			{
				id: 'status',
				text: 'Status',
				path: '/status',
				icon: 'Apps',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_STATUS],
				element: <CP.STATUS />,
			},
			{
				id: 'conferencia',
				text: 'Conferência da receita',
				path: '/conferencia',
				icon: 'AttachMoney',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_CONFERENCIA_RECEITA],
				element: <CP.CONFERENCIA />,
			},
			{
				id: 'metabase',
				text: 'Roteiro de Análise',
				path: '/metabase',
				icon: 'Analytics',
				roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_ROTEIRO_ANALISE],
				element: <CP.METABASE />,
			},
		]
	},
	{
	 	id: 'configuracoes',
	 	text: 'Configurações',
	 	path: '/configuracoes',
	 	icon: 'Settings',
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_INTEGRACAO, roles.ROLE_INTEGRACAO_LEITURA, roles.ROLE_PERFIS_ACESSO],
		element: <CP.CONFIGURACOES />,
	},
	{
	  	id: 'dashboard',
	  	text: 'Dashboard',
	  	path: '/dashboard',
	  	icon: 'PieChartOutline',
	 	roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_DASHBOARDS],
	 	element: <CP.DASHBOARD />,
		onlyEnv: ['QA','DEV'],
	},
	/**
	 * Routes only
	*/
	{
		id: 'reportDownload',
		text: 'Report Download',
		path: '/reports/:id',
		element: <ReportDownload />,
		roles: [],
		onlyRoute: true,
	},
	{
		id: '404',
		text: 'Página não Encontrada',
		path: '/404',
		roles: [],
		element: <AUTH.PAGE_404 />,
		onlyRoute: true,
	},
	{
		id: 'login',
		text: 'Login',
		path: '/login',
		roles: [],
		element: <Login />,
		onlyRoute: true,
	},
	{
		id: 'lock',
		text: 'Lock',
		path: '/lock',
		roles: [],
		element: <LockScreen />,
		onlyRoute: true,
	},
	{
		id: 'atendimento',
		text: 'Atendimento',
		path: '/atendimento/:numeroAtendimento',
		element: <CP.ATENDIMENTOS />,
		roles: [roles.ADMINISTRADOR_PLANISA, roles.ROLE_ATENDIMENTOS],
		onlyRoute: true,
	},
	{
		id: 'acessoKpih',
		text: 'Acesso Kpih',
		path: '/acessoKpih',
		element: <CP.HOME />,
		roles: [],
		onlyRoute: true,
	},

];
export const filterMenuByAcessos = (acessos: string[]): RoutesProps[] => {
	return contentRoutes
			.filter(item => !item.onlyRoute && (item.roles?.length === 0 || item.roles?.some(r => acessos.includes(r))))
			.map(item => {
				if (item.subMenu && item.subMenu.length > 0) {
					item.subMenu = item.subMenu.filter(item => !item.onlyRoute && (item.roles?.length === 0 || item.roles?.some(r => acessos.includes(r))));
				}
				return item;
			});
};
export const filterRoutesByAcessos = (acessos: string[]): RoutesProps[] => {
	let routes:RoutesProps[] = [];
	contentRoutes
			.filter(item => item.roles?.length === 0 || item.roles?.some(r => acessos.includes(r)))
			.forEach(item => {
				if (item.subMenu && item.subMenu.length > 0) {
					item.subMenu.filter((item:any) => item.roles?.length === 0 || item.roles?.some((r:any) => acessos.includes(r)))
									.forEach((sub:any) => routes.push(sub))
				} else {
					routes.push(item);
				}
			});
	return routes;
};
export const findById = (id: string): RoutesProps | undefined => {
	return contentRoutes
			.find(item => item.id === id);
};

export default contentRoutes;