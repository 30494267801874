import React from "react";
import { Provider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from "react-dom/client"; // For React 18
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/styles.scss";
import App from "./App";
import { ThemeContextProvider } from "./context/appContext";
import { AuthContextProvider } from "./context/authContext";
import { LocalContextProvider } from "./context/localContext";
import "./i18n";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { ptBR } from 'date-fns/locale';
registerLocale('ptBR', ptBR);
setDefaultLocale('ptBR')

const rollbarConfig = {
  accessToken: "df1905f104424e16996d991b48f1a463",
};

const children = (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <AuthContextProvider>
        <ThemeContextProvider>
          <Router>
            <LocalContextProvider>
              <App />
            </LocalContextProvider>
          </Router>
        </ThemeContextProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  </Provider>
);

const container = document.getElementById("root");

createRoot(container as Element).render(children); // For React 18
