import React, { SVGProps } from 'react';

const SvgCustomBrazil = (props: SVGProps<SVGSVGElement>) => (


	<svg
		width='1em'
		height='1em'
		viewBox='0 0 512 512'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className='svg-icon'
		{...props}>
		<path
		style={{
			fill: "#73AF00",
		}}
		d="M473.655,88.275H38.345C17.167,88.275,0,105.442,0,126.62V385.38 c0,21.177,17.167,38.345,38.345,38.345h435.31c21.177,0,38.345-17.167,38.345-38.345V126.62 C512,105.442,494.833,88.275,473.655,88.275z"
		/>
		<path
		style={{
			fill: "#FFE15A",
		}}
		d="M251.41,135.207L65.354,248.458c-5.651,3.439-5.651,11.641,0,15.081L251.41,376.792 c2.819,1.716,6.36,1.716,9.18,0l186.056-113.252c5.651-3.439,5.651-11.641,0-15.081L260.59,135.207 C257.771,133.492,254.229,133.492,251.41,135.207z"
		/>
		<circle
		style={{
			fill: "#41479B",
		}}
		cx={256}
		cy={255.999}
		r={70.62}
		/>
		<g>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M195.4,219.872c-3.332,5.578-5.905,11.64-7.605,18.077c39.149-2.946,97.062,8.006,133.922,43.773 c2.406-6.141,3.995-12.683,4.59-19.522C288.247,230.167,235.628,218.776,195.4,219.872z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M258.925,280.099l1.88,5.638l5.943,0.046c0.769,0.006,1.088,0.988,0.469,1.445l-4.781,3.531 l1.793,5.666c0.232,0.734-0.604,1.341-1.229,0.893l-4.835-3.456l-4.835,3.456c-0.626,0.447-1.461-0.159-1.229-0.893l1.793-5.666 l-4.781-3.531c-0.619-0.457-0.3-1.439,0.469-1.445l5.943-0.046l1.88-5.638C257.649,279.368,258.681,279.368,258.925,280.099z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M282.024,294.683l0.809,2.426l2.558,0.02c0.331,0.002,0.468,0.425,0.202,0.622l-2.058,1.519 l0.771,2.439c0.099,0.316-0.259,0.577-0.53,0.384l-2.081-1.487l-2.081,1.487c-0.269,0.193-0.629-0.068-0.529-0.384l0.771-2.439 l-2.058-1.519c-0.267-0.196-0.129-0.619,0.202-0.622l2.558-0.02l0.809-2.426C281.474,294.368,281.919,294.368,282.024,294.683z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M248.938,269.388l0.809,2.426l2.558,0.02c0.331,0.002,0.469,0.425,0.202,0.622l-2.058,1.519 l0.771,2.439c0.099,0.316-0.259,0.577-0.529,0.384l-2.081-1.487l-2.081,1.487c-0.269,0.193-0.629-0.068-0.53-0.384l0.771-2.439 l-2.058-1.519c-0.266-0.196-0.129-0.619,0.202-0.622l2.558-0.02l0.809-2.426C248.388,269.076,248.833,269.076,248.938,269.388z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M204.13,266.446l0.809,2.426l2.558,0.02c0.331,0.002,0.469,0.425,0.202,0.622l-2.058,1.519 l0.771,2.439c0.099,0.316-0.259,0.577-0.53,0.384l-2.081-1.487l-2.081,1.487c-0.269,0.192-0.629-0.068-0.529-0.384l0.771-2.439 l-2.058-1.519c-0.267-0.196-0.129-0.619,0.202-0.622l2.558-0.02l0.809-2.426C203.581,266.133,204.025,266.133,204.13,266.446z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M241.614,293.846l0.809,2.426l2.558,0.02c0.331,0.002,0.469,0.425,0.202,0.622l-2.058,1.519 l0.771,2.439c0.099,0.316-0.259,0.577-0.529,0.384l-2.081-1.487l-2.081,1.487c-0.269,0.193-0.629-0.068-0.53-0.384l0.771-2.439 l-2.058-1.519c-0.266-0.196-0.129-0.619,0.202-0.622l2.558-0.02l0.809-2.426C241.065,293.532,241.51,293.532,241.614,293.846z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M220.99,264.753l0.662,1.984l2.092,0.017c0.27,0.002,0.383,0.348,0.166,0.509l-1.683,1.242 l0.631,1.994c0.082,0.258-0.212,0.472-0.433,0.314l-1.702-1.216l-1.702,1.216c-0.221,0.158-0.514-0.056-0.433-0.314l0.631-1.994 l-1.683-1.242c-0.217-0.161-0.106-0.507,0.166-0.509l2.092-0.017l0.662-1.984C220.541,264.497,220.904,264.497,220.99,264.753z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M283.819,223.794l0.828,2.482l2.616,0.02c0.339,0.002,0.479,0.435,0.206,0.637l-2.104,1.554 l0.789,2.495c0.103,0.323-0.266,0.59-0.541,0.393l-2.129-1.522l-2.129,1.522c-0.276,0.198-0.643-0.071-0.541-0.393l0.789-2.495 l-2.104-1.554c-0.273-0.201-0.132-0.633,0.206-0.637l2.616-0.02l0.828-2.482C283.257,223.47,283.712,223.47,283.819,223.794z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M207.012,252.615l0.662,1.984l2.092,0.017c0.27,0.002,0.383,0.348,0.166,0.509l-1.683,1.242 l0.631,1.994c0.082,0.258-0.212,0.472-0.433,0.314l-1.702-1.216l-1.702,1.216c-0.221,0.158-0.514-0.056-0.433-0.314l0.631-1.994 l-1.683-1.242c-0.218-0.161-0.106-0.506,0.166-0.509l2.092-0.017l0.662-1.984C206.563,252.358,206.926,252.358,207.012,252.615z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M217.112,280.581l1.002,3.005l3.168,0.024c0.41,0.003,0.58,0.526,0.25,0.77l-2.549,1.882l0.956,3.02 c0.124,0.391-0.321,0.715-0.655,0.476l-2.578-1.843l-2.578,1.843c-0.333,0.238-0.779-0.085-0.655-0.476l0.956-3.02l-2.549-1.882 c-0.33-0.244-0.16-0.767,0.25-0.77l3.168-0.024l1.002-3.005C216.433,280.191,216.983,280.191,217.112,280.581z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M294.903,295.313l0.631,1.891l1.993,0.015c0.258,0.002,0.365,0.331,0.158,0.484l-1.603,1.184 l0.601,1.9c0.077,0.246-0.202,0.449-0.413,0.299l-1.621-1.159l-1.622,1.159c-0.21,0.15-0.49-0.053-0.413-0.299l0.601-1.9 l-1.603-1.184c-0.207-0.153-0.1-0.482,0.158-0.484l1.993-0.015l0.63-1.891C294.475,295.068,294.822,295.068,294.903,295.313z"
		/>
		<path
			style={{
			fill: "#F5F5F5",
			}}
			d="M301.877,280.883l0.809,2.426l2.558,0.02c0.331,0.002,0.469,0.425,0.202,0.622l-2.058,1.519 l0.771,2.439c0.099,0.316-0.259,0.577-0.529,0.384l-2.081-1.487l-2.081,1.487c-0.269,0.193-0.629-0.068-0.529-0.384l0.771-2.439 l-2.058-1.519c-0.266-0.196-0.129-0.619,0.202-0.622l2.558-0.02l0.809-2.426C301.327,280.568,301.772,280.568,301.877,280.883z"
		/>
		</g>
	</svg>
);

export default SvgCustomBrazil;
