import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageWrapper from '../../components/layout/Content/PageWrapper';
import Page from '../../components/layout/Content/Page';
import { Card } from 'react-bootstrap';
import Logo from '../../assets/img/logo2.png';
import { MdLock } from 'react-icons/md';
import Spinner from '../../components/bootstrap/Spinner';

const LockScreen: FC = () => {
	const { t } = useTranslation('login');

	const navigate = useNavigate();
	const [mensagemLockScreen, setMensagemLockScreen] = useState<string>();

	useEffect(() => {
	  if(localStorage.getItem('custoPaciente_lockScreen')) {
		setMensagemLockScreen(localStorage.getItem('custoPaciente_lockScreen') || undefined)
	  }
	}, []);

	useEffect(() => {
	  const handleStorageChange = (event:any) => {
		if (event.key === 'custoPaciente_lockScreen') {
		  // Atualizar o estado para exibir o modal
		  setMensagemLockScreen(localStorage.getItem('custoPaciente_lockScreen') || undefined);
		  if(localStorage.getItem('custoPaciente_lockScreen') == null) {
			navigate('/')
			window.location.reload();
		  }
		}
	  };
  
	  window.addEventListener('storage', handleStorageChange);
  
	  return () => {
		// Remover o listener quando o componente for desmontado
		window.removeEventListener('storage', handleStorageChange);
	  };
	}, []);

	return (
		<PageWrapper
			title='Processando'>
			<Page className='p-0'>
				<div className='h-100 row d-flex align-items-center'>
					<div className='row justify-content-center'>
						<h1 className='brand-title d-flex justify-content-center mb-4'>
							<img src={Logo} alt='' height={64} />
						</h1>
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-lg shadow-3d-light' data-tour='login-page'>
								<Card.Body className='px-4 mx-4 text-center'>
									
									<div className='h1'><MdLock /></div>
									<div className='h4 text-muted mb-5'>
										Sistema Bloqueado
									</div>
									<Spinner
										size='large'
										color='primary' />
									<div className='text-muted my-5'>
										{mensagemLockScreen}
									</div>
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
LockScreen.propTypes = {
	isLocked: PropTypes.bool,
};
LockScreen.defaultProps = {
	isLocked: false,
};

export default LockScreen;
