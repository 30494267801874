import React, { Suspense } from 'react';
import PageWrapper from './PageWrapper';
import Page from './Page';
import ContentRoutes from './ContentRoutes';
import { Card } from 'react-bootstrap';
import SubHeader from '../Header/SubHeader';

const LOADING = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card>
						<Card.Body>
							<div />
						</Card.Body>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card>
						<Card.Body>
							<div />
						</Card.Body>
					</Card>
					<Card>
						<Card.Body>
							<div />
						</Card.Body>
					</Card>
				</div>
			</div>
		</Page>
	</PageWrapper>
);

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={LOADING}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
