import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../components/layout/Content/PageWrapper';
import Page from '../../components/layout/Content/Page';
import apiRb from '../../services/apiRb';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaArrowUp } from 'react-icons/fa';
import { Spinner } from 'react-bootstrap';

const ReportDownload = () => {
    const { id } = useParams();
    const reportId = id;
    const { t } = useTranslation(['mensagem']);

    const hasDownloaded = useRef(false);  // Persist download state across renders
    const [loading, setLoading] = useState(false);  // To track if download is in progress
    const [downloadComplete, setDownloadComplete] = useState(false);  // To track download completion

    useEffect(() => {
        if (hasDownloaded.current) return;  // Prevent multiple downloads

            setLoading(true);  // Start loading when the download begins

        apiRb.get(`/reports/download/${reportId}`, { responseType: 'blob'})
            .then(response => response.data)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio_atendimentos.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);  // Clean up

                hasDownloaded.current = true;  // Mark as downloaded
                setLoading(false);  // Stop loading after download is complete
                setDownloadComplete(true);  // Mark download as complete
            })
            .catch(error => {
                console.log(error);
                setLoading(false);  // Stop loading if there's an error
            });
    }, [reportId]);

    return (
        <PageWrapper title={"Download"}>
			<Page>
				<div className='row d-flex align-items-center h-90'>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
                        {/* Show the spinner if loading */}
                        {loading ? (
                            <>
                                <Spinner animation="border" role="status" style={{ width: "4rem", height: "4rem" }}>
                                    <span className="visually-hidden">Baixando...</span>
                                </Spinner>
                                <div className="text-dark fw-bold fs-3 mt-3">
                                    {t('mensagem.downloading')}
                                </div>
                            </>
                        ) : downloadComplete ? (
                            <div className="text-dark fw-bold fs-3">
                                {t('mensagem.download_complete')}
                                <center><p><FaArrowUp size={200} /></p></center>
                            </div>
                        ) : (
                            <div className="text-dark fw-bold fs-3">
                                {t('mensagem.waiting_for_download')}
                            </div>
                        )}
					</div>
				</div>
			</Page>
		</PageWrapper>
    );
}

export default ReportDownload;
