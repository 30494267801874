import React, { createContext, useLayoutEffect, useState, useMemo, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useDeviceScreen from '../hooks/useDeviceScreen';

export interface IAppContextProps {
	asideStatus: boolean;
	mobileDesign: boolean;
	setAsideStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
}
const ThemeContext = createContext<IAppContextProps>({} as IAppContextProps);

interface IAppContextProviderProps {
	children: ReactNode;
}
export const ThemeContextProvider: FC<IAppContextProviderProps> = ({ children }) => {
	const deviceScreen = useDeviceScreen();
	// @ts-ignore
	const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;

	const [asideStatus, setAsideStatus] = useState(
		localStorage.getItem('custoPaciente_asideStatus')
			? localStorage.getItem('custoPaciente_asideStatus') === 'true'
			: // @ts-ignore
			  deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE,
	);
	useLayoutEffect(() => {
		localStorage.setItem('custoPaciente_asideStatus', asideStatus?.toString());
	}, [asideStatus]);

	useLayoutEffect(() => {
		// @ts-ignore
		if (deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) {
			if (localStorage.getItem('custoPaciente_asideStatus') === 'true') 
				setAsideStatus(true);
		}
		return () => {
			setAsideStatus(false);
		};
	}, [deviceScreen.width]);

	const values: IAppContextProps = useMemo(
		() => ({
			mobileDesign,
			asideStatus,
			setAsideStatus,
		}),
		[
			asideStatus,
			mobileDesign,
		],
	);

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};
ThemeContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeContext;
