import React, { useContext, FC, useState, useEffect, useRef } from 'react';
import AppContext from '../../../context/appContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from './Aside';
import Brand from './Brand';
import { useTranslation } from 'react-i18next';
import Navigation from '../Navigation/Navigation';
import Item from '../Navigation/Item';
import AuthContext from '../../../context/authContext';
import useLogout from '../../../hooks/useLogout';
import { IUserProps, User } from '../../../common/data/IUserProps';
import { IUnidadeProps } from '../../../common/data/IUnidadeProps';
import { TOKEN_KEY, UNIDADE_KEY, USER_KEY } from '../../../context/contextKeys';
import { RoutesProps, filterMenuByAcessos } from '../../../routes/contentRoutes';
import { useNavigate } from 'react-router-dom';

const DefaultAside: FC = () => {
	const { t } = useTranslation('menu');
	const { asideStatus, setAsideStatus } = useContext(AppContext);
	const { getUserData } = useContext(AuthContext);
	const [user, setUser] = useState<IUserProps>();
	const [unidade, setUnidade] = useState<IUnidadeProps>();
	const [menus, setMenus] = useState<RoutesProps[]>([]);
	const logout = useLogout();
	const navigate = useNavigate();
	const spanRef = useRef(null);

	const handleLogout = () => {
		logout();
	}

	const handleAbrirSuporte = () => {
		const btnSuporteHeader = document.getElementById('btn-suporte-header');

		if (btnSuporteHeader) {
			btnSuporteHeader.click();
		}
	}

	useEffect(() => {
		// Chamada assíncrona ou que pode causar atualizações de estado ou contexto
		const fetchMenus = async () => {
			if(getUserData()){
				let acessos = [];
				acessos.push(getUserData().role);
				acessos = acessos.concat(getUserData().acessos);
				const userMenus = filterMenuByAcessos(acessos);
				setMenus(userMenus);
			}
		};
	
		if(!localStorage.getItem(TOKEN_KEY) || !localStorage.getItem(UNIDADE_KEY)) {
			navigate('/login')
		} else {
			// Chame a função assíncrona dentro do useEffect
			fetchMenus();
			const handleClick = (e:any) => {
				e.preventDefault();
			};

			if (spanRef.current) {
				// @ts-ignore
				spanRef.current.addEventListener('click', handleClick);
			}
		}
		return () => {
			if (spanRef.current) {
				// @ts-ignore
				spanRef.current.removeEventListener('click', handleClick);
			  }
		}
	}, []);

	useEffect(() => {
		const storedUser = localStorage.getItem(USER_KEY);

		if (storedUser) {
			const localUser = JSON.parse(storedUser);
			setUser(new User(localUser));
		}
			
		const storedUnidade = localStorage.getItem(UNIDADE_KEY);

		if (storedUnidade) {
			const localUnidade = JSON.parse(storedUnidade);
			setUnidade(localUnidade);
		}
	}, []);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<>
					<Navigation className='mt-4' menu={menus} id='aside-menu' />
				</>
			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						<span ref={spanRef} onClick={handleAbrirSuporte}>
							<Item rootId='footer-support' icon='ContactSupport' title='Suporte' />
						</span>
					</div>
				</nav>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						<span onClick={handleLogout}>
							<Item rootId='footer' icon='Logout' title='Sair'/>
						</span>
					</div>
				</nav>
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
