import { CIDS_KEY, CONVENIOS_KEY, DRGS_KEY, ESPECIALIDADES_KEY, MEDICOS_KEY, 
          PROCEDIMENTOS_CIRURGICOS_KEY, PROCEDIMENTOS_KEY, COMPETENCIAS_KEY,
          TIPOS_ATENDIMENTO_KEY, TIPOS_ORIGEM_PACIENTE_KEY, TOKEN_KEY, UNIDADE_KEY,
          USER_KEY, LOCK_KEY } from "../context/contextKeys";


export const getUnidade = () => {
  if(localStorage.getItem(UNIDADE_KEY)) {
    // @ts-ignore
    let unidade = JSON.parse(localStorage.getItem(UNIDADE_KEY));
    if(unidade) {
      return unidade;
    }
  }
  return null;
}
export const getToken = () => {
  if(localStorage.getItem(TOKEN_KEY)) {
    // @ts-ignore
    let token = JSON.parse(localStorage.getItem(TOKEN_KEY));
    if(token) {
      return token;
    }
  }
  return null;
}

export const authLogout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(UNIDADE_KEY);
  localStorage.removeItem(LOCK_KEY);
  localStorage.removeItem(CONVENIOS_KEY);
  localStorage.removeItem(ESPECIALIDADES_KEY);
  localStorage.removeItem(PROCEDIMENTOS_KEY);
  localStorage.removeItem(DRGS_KEY);
  localStorage.removeItem("custoPaciente_procedimentosDosConvenios");
  localStorage.removeItem(PROCEDIMENTOS_CIRURGICOS_KEY);
  localStorage.removeItem(TIPOS_ATENDIMENTO_KEY); // TODO: remover posteriormente
  localStorage.removeItem(TIPOS_ORIGEM_PACIENTE_KEY);
  localStorage.removeItem(MEDICOS_KEY);
  localStorage.removeItem(CIDS_KEY);
  localStorage.removeItem(COMPETENCIAS_KEY);
}