import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultAside from '../components/layout/Aside/DefaultAside';

const asides: RouteProps[] = [
	{ path: '/login', element: null },
	{ path: '/page404', element: null },
	{ path: '/lock', element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
