import { useContext, useState } from 'react';
import AuthContext from '../context/authContext';
import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

	function limparCookies() {
    // Obtém todos os cookies
    const cookies = document.cookie.split(';');
    
    // Itera sobre os cookies e os remove
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const nome = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
      document.cookie = `${nome}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  const handleLogout = () => {
    limparCookies();
    logout();
    navigate('/login');
  };

  return handleLogout;
};

export default useLogout;