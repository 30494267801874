import React, { FC, ReactNode, useEffect, useState, useContext } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../components/layout/Content/PageWrapper';
import Page from '../../components/layout/Content/Page';
import { Card, Form } from 'react-bootstrap';
import Button from '../../components/bootstrap/Button';
import Select from 'react-select';
import apiRb from '../../services/apiRb';
import AuthContext from '../../context/authContext';
import { IUserProps } from '../../common/data/IUserProps';
import Logo from '../../assets/img/logo2.png';
import LocalContext from '../../context/localContext';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import { useToasts } from 'react-toast-notifications';
import Toasts from '../../components/bootstrap/Toasts';
import { IUnidadeProps } from '../../common/data/IUnidadeProps';

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { addToast } = useToasts();
	const { setUserData, token, setToken, unidade, setUnidade } = useContext(AuthContext);
	const { loadContext } = useContext(LocalContext);
	const { t } = useTranslation('login');
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [unidades, setUnidades] = useState<any>([]);
	const [options, setOptions] = useState<any>([]);
	const [unidadeSelecionada, setUnidadeSelecionada] = useState<any>();
	const [forceUpdate, setForceUpdate] = useState(false);


	const navigate = useNavigate();
	const handleForceUpdate = () => {
		setForceUpdate((prev) => !prev);
	};

	useEffect(() => {
		handleForceUpdate();
	}, [])

	useEffect(() => {
		if(token){
			setIsAuthenticated(true);
			if(unidade) {
				setUnidadeSelecionada(unidade);
				_handleSubmitUnidadePreLogada(unidade);
				loadContext();
			}
		} else {
			setIsAuthenticated(false)
		}
	}, []);

	useEffect(() => {
		if(isAuthenticated) {
			apiRb.get('/unidades/minhas_unidades')
				.then(response => response.data)
				.then(response => {
					setUnidades(response);
				})
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if(unidades.length > 0) {
			let all = [];
			for (let i = 0; i < unidades.length; i++) {
				let cur = unidades[i];
				all.push({"value": cur.id, "label": cur.nome,
					"modulo_precificacao": cur.temModuloPrecificacao, "modulo_acompanhamento": cur.temModuloAcompanhamento});
			}
			setOptions(all);
		}
	}, [unidades]);

	function _handleSubmitLogin(values: any) {
		let urlencoded = new URLSearchParams();
		urlencoded.append("email", values.loginUsername);
		urlencoded.append("password", values.loginPassword);

		apiRb.post('/auth/login', urlencoded)
			.then(response => {
				if(response.status != 200) {
					formik.setFieldError('loginPassword', 'Nome de usuário e/ou senha estão incorretos!');
					formik.setFieldError('loginUsername', 'Nome de usuário e/ou senha estão incorretos!');

					addToast(
						<Toasts
							icon='Cancel'
							iconColor='danger'
							title='Falha na autenticação'>
							Nome de usuário e/ou senha estão incorretos!
						</Toasts>,
						{
							autoDismiss: true,
						}
					);
					return;
				}
				else {
					return response.data;
				}
			})
			.then(response => {
				setToken(response.token);
				setIsAuthenticated(true);
			}).catch(e => {
				console.log('error', e)
			});
	}

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			_handleSubmitLogin(values);
		},
	});

	const logarNaUnidade = (unidade: IUnidadeProps) => {
		let params = {
			email: formik.values.loginUsername,
			password: formik.values.loginPassword,
			unidade_id: unidade.id
		}

		apiRb.post('/auth/logar_na_unidade', params)
			.then(response => {
				if(response.status == 200) {
					return response.data;
				}
			})
			.then(response => {
				let user:IUserProps = {
					id: response.id,
					email: response.email,
					login: response.login,
					nome: response.nome,
					role: response.role,
					acessos: response.acessos,
				};
				setToken(response.token);
				setUserData(user);
				setIsAuthenticated(true);
				loadContext();
			});
	}

	const _handleSubmitUnidadePreLogada = (unidade:IUnidadeProps) => {
		if(unidade) {
			setUnidade(unidade);
			logarNaUnidade(unidade);
			setTimeout(() => navigate('/'), 1000);
		}
	}

	const _handleSubmitUnidade = () => {
		if(unidadeSelecionada) {
			let unidade: IUnidadeProps = {
				id: unidadeSelecionada.value,
				nome: unidadeSelecionada.label,
				modulo_precificacao: unidadeSelecionada.modulo_precificacao,
				modulo_acompanhamento: unidadeSelecionada.modulo_acompanhamento
			};
			setUnidade(unidade);
			logarNaUnidade(unidade);
			setTimeout(() => navigate('/'), 1000);
		}
	}

	return (
		<PageWrapper
			isProtected={false}
			title='Bem-vindo'
			key={JSON.stringify(forceUpdate)}>
			<Page className='p-0'>
				<div className='h-100 row d-flex align-items-center'>
					<div className='row justify-content-center'>
						<h1 className='brand-title d-flex justify-content-center mb-4'>
							<img src={Logo} alt='' height={64} />
						</h1>
						<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
							<Card className='shadow-lg shadow-3d-light' data-tour='login-page'>
								<Card.Body className='px-4 mx-4'>
									
									<div className='text-center h1 fw-bold mt-5'>{t('labelLogin.header') as ReactNode}</div>
									<div className='text-center h4 text-muted mb-5'>
										{ !isAuthenticated ? 
											t('labelLogin.subHeader') as ReactNode
											:
											t('labelLogin.subHeaderUnidade') as ReactNode
										}
									</div>
									<form className='row g-4'>
										<>
											{ !isAuthenticated ? (
												<div className='col-12'>
													<FormGroup className='mb-3' id='loginUsername'
														label={`${t('labelLogin.campoEmail')}`}>
														<Input type="text" 
															value={formik.values.loginUsername}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															onFocus={() => {
																formik.setErrors({});
															}}
															isTouched={formik.touched.loginUsername}
															invalidFeedback={
																formik.errors.loginUsername
															}
															isValid={formik.isValid}
															autoComplete='username'
															placeholder={`${t('labelLogin.campoEmail') as ReactNode}`} />
													</FormGroup>
													<FormGroup className='mb-3' id='loginPassword'
														label={`${t('labelLogin.campoSenha')}`}>
														<Input type="password" 
															value={formik.values.loginPassword}
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
															onFocus={() => {
																formik.setErrors({});
															}}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															autoComplete='current-password'
															placeholder={`${t('labelLogin.campoSenha') as ReactNode}`} />
													</FormGroup>
												</div>
											) : (
												unidades && (
													<Select
														autoFocus
														isClearable
														isSearchable
														name='unidadeId'
														options={options}
														onChange={(e) => setUnidadeSelecionada(e)}
														placeholder='Selecione uma unidade'
														noOptionsMessage={() => 'Nada encontrado'}
														className="basic-select"
														classNamePrefix="select"
													/>
												)
											)}
											<div className='col-12 py-4'>
												{ !isAuthenticated ? (
													<Button
														color='info'
														className='w-100 py-3'
														type='button'
														onClick={formik.submitForm}>
														{t('labelLogin.btnEntrar') as ReactNode}
													</Button>
												) : (
													<Button
														color='info'
														className='w-100 py-3'
														type='button'
														onClick={_handleSubmitUnidade}>
														{t('labelLogin.btnUnidade') as ReactNode}
													</Button>
												)}
											</div>
										</>
									</form>
								</Card.Body>
							</Card>
							<div className='text-center pt-2'>
								<a
									href=''
									className='text-decoration-none me-3 link-light'>
									{t('labelLogin.linkPrivacidade') as ReactNode}
								</a>
								<a
									href=''
									className='text-decoration-none link-light'>
									{t('labelLogin.linkTermos') as ReactNode}
								</a>
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
