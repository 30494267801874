import React, { useEffect, useRef, useState } from 'react';

import Wrapper from './components/layout/Portal/Wrapper';
import Portal from './components/layout/Portal/Portal';
import { ReactNotifications } from 'react-notifications-component';
import { ToastProvider } from 'react-toast-notifications';
import { Toast, ToastContainer } from './components/bootstrap/Toasts';
import AsideRoutes from './components/layout/Aside/AsideRoutes';
import LockScreen from './pages/lockScreen/LockScreen';
import HeaderRoutes from './components/layout/Header/HeaderRoutes';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Buffer } from 'buffer';
const App: React.FC = () => {

	dayjs.extend(localizedFormat);
	dayjs.extend(relativeTime);
  
  window.Buffer = Buffer;
	const ref = useRef(null);
  const [showLockScreen, setShowLockScreen] = useState(false);

  useEffect(() => {
    if(localStorage.getItem('custoPaciente_lockScreen')) {
      setShowLockScreen(true)
    }
  }, [])
  useEffect(() => {
    const handleStorageChange = (event:any) => {
      if (event.key === 'custoPaciente_lockScreen') {
        // Atualizar o estado para exibir o modal
        setShowLockScreen(true);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Remover o listener quando o componente for desmontado
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
        <>
        <ToastProvider components={{ ToastContainer, Toast }}>
            <div
              ref={ref}
              className={`app ${showLockScreen ? 'bg-primary' : 'bg-white'}`}>
              
              { showLockScreen ? 
                (<>
                  <HeaderRoutes />
                  <LockScreen />
                </>) : 
                (<>
                  <AsideRoutes />
                  <Wrapper />
                </>)}
            </div>
            <Portal id='portal-notification'>
              <ReactNotifications />
            </Portal>
        </ToastProvider>
        </>
  );

};

export default App;
