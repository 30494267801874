import React, { useContext } from 'react';
import Header from './Header';
import AppContext from '../../../context/appContext';
import CommonHeaderRight from './CommonHeaderRight';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/img/logo2.png';

const DefaultHeader = () => {
	const { asideStatus } = useContext(AppContext);
	return (
		<Header>
			<div className='d-flex'>
				<div className='me-auto'>
					<h1 className='brand-title '>
						<Link to='/' aria-label='Logo'>
							{ !asideStatus &&
								<img src={Logo} alt='' height={42} />
							}
						</Link>
					</h1>
				</div>
				<CommonHeaderRight />
			</div>
		</Header>
	);
};

export default DefaultHeader;
