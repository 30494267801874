import axios from "axios";
import { authLogout, getUnidade, getToken } from "./auth";

const apiRb = axios.create({
  baseURL: "https://custopaciente-qa.kpih.com.br/backend/api/v1",
});

apiRb.interceptors.request.use(async (config) => {
  const unidade = getUnidade();
  if (unidade && unidade.id) {
    config.headers["x-unidade-id"] = unidade.id;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers.Authorization = `Bearer ${getToken()}`;
  config.timeout = 9999999;
  config.maxContentLength = 100000000;
  config.maxBodyLength = 100000000;

  return config;
});

apiRb.interceptors.response.use(response => {
  return response;
}, error => {
  if (getToken() && error.response.status === 401) {
    authLogout();
    window.location.href = '/login';
  }
  return error.response;
});

export default apiRb;
