export const TOKEN_KEY = 'custoPaciente_token';
export const USER_KEY = 'custoPaciente_userData';
export const UNIDADE_KEY = 'custoPaciente_unidade';
export const LOCK_KEY = 'custoPaciente_lockScreen';

export const COMPETENCIAS_KEY = 'custoPaciente_competencias';
export const CONVENIOS_KEY = 'custoPaciente_convenios';
export const ESPECIALIDADES_KEY = 'custoPaciente_especialidades';
export const PROCEDIMENTOS_KEY = 'custoPaciente_procedimentos';
export const DRGS_KEY = 'custoPaciente_drgs';
export const PROCEDIMENTOS_CIRURGICOS_KEY = 'custoPaciente_procedimentosCirurgicos';
export const TIPOS_ATENDIMENTO_KEY = 'custoPaciente_tiposAtendimento';
export const TIPOS_ORIGEM_PACIENTE_KEY = 'custoPaciente_tiposOrigemPaciente';
export const MEDICOS_KEY = 'custoPaciente_medicos';
export const CIDS_KEY = 'custoPaciente_cids';