import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultHeader from '../components/layout/Header/DefaultHeader';


const headers: RouteProps[] = [
	{ path: '/login', element: null },
	{ path: '/page404', element: null },
	{ path: '/lock', element: null },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
