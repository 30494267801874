import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthContext from '../../../context/authContext';

const ContentRoutes = () => {
	const { getUserRoutes } = useContext(AuthContext);

	const userRoutes = getUserRoutes();
  
	if (!userRoutes || !Array.isArray(userRoutes)) {
	  return <Navigate to="/logout" />;
	}
  
	return (
	  <Routes>
		{userRoutes.map((page) => (
		  // eslint-disable-next-line react/jsx-props-no-spreading
		  <Route key={page.path} {...page} />
		))}
		<Route path="" element={<Navigate to="/" />} />
		<Route path="*" element={<Navigate to="/404" />} />
	  </Routes>
	);
  };
  
  export default ContentRoutes;
